import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "../../../utils/hooks";
import {
  Button,
  Icons,
  Toast,
  GraphicSwitchButton,
  InputGroup,
  Modal,
  SelectInput,
} from "@pushpress/shared-components";
import me from "../../../../public/images/me.svg";
import other from "../../../../public/images/otherParticipant.svg";
import { useGetLinkedAccounts } from "../../../api.hooks";
import { FREE_PAYMENT_ID } from "../../../utils/constants";
import PageContainer from "../../../components/PageContainer";
import PageTitle from "../../../components/PageTitle";
import NewAccountModal from "../../../components/NewAccountModal";
import { useAppContext } from "../../../contexts/AppContext";
import { usePlanContext } from "../../../contexts/PlanContext";
import PlanSummary from "../../../components/PlanSummary";
import SwitchButtonGroup from "../../../components/SwitchButtonGroup";
import { useAnalytics } from "../../../components/AnalyticsProvider";

const Participant: React.FC = () => {
  const { t } = useTranslation("plan");
  const analytics = useAnalytics();
  const { user } = useAppContext();
  const {
    setParticipant,
    participant,
    goToScreen,
    participantType,
    participantTypeVisible,
    isLinkedAccount,
    isFree,
    paying,
    calendarItem,
    bookingError,
    setParticipantType,
    purchasePlan,
  } = usePlanContext();

  const [modalAccountVisible, modalAccountActions] = useToggle(false);

  const { data: linkedAccounts = [], refetch: refetchLinkedAccounts } =
    useGetLinkedAccounts();

  const trackParticipantTypeEvent = (type: ParticipantType) => {
    if (type === "me") {
      analytics.trackEvent("booked_self", {}, true);
    } else {
      analytics.trackEvent("booked_someone_else", {}, true);
    }
  };

  const selectParticipantType = (type: ParticipantType) => {
    setParticipantType(type);
    if (type === "me") {
      setParticipant(user as User);
    } else {
      if (participant && participant.userUuid !== user?.userUuid) {
        return;
      }
      if (linkedAccounts.length > 0) {
        setParticipant(linkedAccounts[0]);
      } else {
        setParticipant(undefined);
      }
    }
    trackParticipantTypeEvent(type);
  };

  const changeAccount = useCallback(
    (userUuid: string) => {
      const part = linkedAccounts.find((a) => a.userUuid === userUuid);
      setParticipant(part);
    },
    [linkedAccounts, setParticipant],
  );

  const closeNewParticipantModal = useCallback(
    (newLinkedAccount?: LinkedAccount) => {
      if (newLinkedAccount) {
        setParticipant(newLinkedAccount);
        void refetchLinkedAccounts();
      }
      modalAccountActions.off();
    },
    [modalAccountActions, refetchLinkedAccounts, setParticipant],
  );

  const goToNextScreen = () => {
    if (isFree) {
      purchasePlan(FREE_PAYMENT_ID, "free", calendarItem?.uuid);
    } else {
      goToScreen("payment");
    }
  };

  const accountOptions = useMemo(() => {
    return linkedAccounts.map((l) => ({
      label: `${l.firstName} ${l.lastName}`,
      value: l.userUuid,
    }));
  }, [linkedAccounts]);

  useEffect(() => {
    if (participant) {
      setParticipantType(
        user?.userUuid === participant.userUuid ? "me" : "other",
      );
    }
  }, [participant, setParticipantType, user?.userUuid]);

  const toastMessage = participantType
    ? t(`participant.${participantType}Desc`)
    : undefined;

  const loading = paying && !bookingError;

  return (
    <>
      <Modal
        visible={modalAccountVisible}
        closeModal={modalAccountActions.off}
        disableClickAway
        disableEscapeKeyDown
      >
        <NewAccountModal closeModal={closeNewParticipantModal} />
      </Modal>
      <PageContainer>
        <PageContainer.LeftPane>
          <PlanSummary />
        </PageContainer.LeftPane>
        <PageContainer.RightPane>
          {participantTypeVisible && (
            <>
              <PageTitle title={t("participant.title")} theme="light" />
              <SwitchButtonGroup className="mb-3">
                <GraphicSwitchButton
                  imageUrl={(me as { src: string }).src}
                  label={t("participant.meLabel")}
                  selected={participantType === "me"}
                  onSelect={() => { selectParticipantType("me") }}
                />
                <GraphicSwitchButton
                  disabled={isLinkedAccount}
                  imageUrl={(other as { src: string }).src}
                  label={t("participant.otherLabel")}
                  selected={participantType === "other"}
                  onSelect={() => { selectParticipantType("other") }}
                />
              </SwitchButtonGroup>
            </>
          )}
          {participantTypeVisible && toastMessage && (
            <Toast
              message={toastMessage}
              icon={Icons.Information}
              iconPosition="center"
            />
          )}
          {participantType === "other" && (
            <div className={participantTypeVisible ? "mt-3" : undefined}>
              <PageTitle title={t("participant.titleInfo")} theme="light" />
              {accountOptions.length > 0 && (
                <InputGroup label={t("participant.linkedAccounts")}>
                  <SelectInput
                    value={participant?.userUuid}
                    options={accountOptions}
                    onValueChange={changeAccount}
                  />
                </InputGroup>
              )}
              <Button
                buttonType="secondary"
                size="large"
                text={t("participant.addNewPerson")}
                icon={Icons.Plus}
                iconLocation="left"
                onClick={modalAccountActions.on}
              />
            </div>
          )}
          <Button
            fullWidth
            className="mt-3"
            buttonType="primary"
            size="large"
            text={t("participant.continue")}
            iconLocation="right"
            icon={Icons.ArrowRight}
            disabled={!participant}
            loading={loading}
            onClick={goToNextScreen}
          />
        </PageContainer.RightPane>
      </PageContainer>
    </>
  );
};

export default Participant;
