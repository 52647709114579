import React from "react";
import PageContainer from "../../../components/PageContainer";
import PlanSummary from "../../../components/PlanSummary";
import { usePlanContext } from "../../../contexts/PlanContext";
import { BottomInfoPanel, Icons } from "@pushpress/shared-components";
import { ALERT_COLOR } from "../../../utils/colors";

const Message = () => {
  const { planInvalidMessage } = usePlanContext();

  if (!planInvalidMessage) {
    return null;
  }

  return (
    <PageContainer>
      <PageContainer.LeftPane>
        <PlanSummary />
      </PageContainer.LeftPane>
      <PageContainer.RightPane>
        <BottomInfoPanel
          infoIcon={() => <Icons.Alert color={ALERT_COLOR} />}
          bodyText={planInvalidMessage.message}
          buttonText={planInvalidMessage.buttonText || undefined}
          buttonLink={planInvalidMessage.buttonLink || undefined}
        />
      </PageContainer.RightPane>
    </PageContainer>
  );
};

export default Message;
